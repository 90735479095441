<template>
  <div
    class="pr-0 pr-sm-1 pb-16"
    v-if="pedidoEditandoObj"
  >
    <!--Botones paginacion de productos-->
    <v-row
      no-gutters
      v-if="$vuetify.breakpoint.mdAndUp && modoPaginacion"
    >
      <v-col
        class="pa-2"
        cols="12"
      >
        <div class="d-flex">
          <v-btn
            :disabled="paginaActualPaginacion.actual == 0"
            large
            color="primary black--text"
            class="px-12 rounded-lg"
            @click="cambiarPagina(-1)"
          ><v-icon>mdi-arrow-left-bold</v-icon></v-btn>
          <v-spacer />
          <div class="text-center">
            <div class="body-2 font-weight-bold pa-0">
              {{ paginaActualPaginacion.actual + 1 }} de
              {{ paginaActualPaginacion.maximo }}
            </div>
            <div class="caption pa-0">
              ({{ paginaActualPaginacion.mostrando }} items)
            </div>
          </div>
          <v-spacer />
          <v-btn
            :disabled="paginaActualPaginacion.actual + 1 == paginaActualPaginacion.maximo
              "
            large
            color="primary black--text"
            class="px-12 rounded-lg"
            @click="cambiarPagina(1)"
          ><v-icon>mdi-arrow-right-bold</v-icon></v-btn>
        </div>
      </v-col>
    </v-row>
    <!--busqueda-->
    <v-row
      no-gutters
      v-if="buscar.length > 0"
    >
      <v-btn
        @click="reiniciarBusqueda"
        color="primary"
        class="black--text mb-4"
      >Reiniciar búsqueda</v-btn>
    </v-row>
    <v-row
      no-gutters
      v-if="resultadosBusqueda.length == 0 && buscar.length > 2"
      class=""
    >
      <v-alert
        color="warning"
        class="white--text"
      >No se han encontrado coincidencias. Ignorando la búsqueda</v-alert>
    </v-row>
    <template v-if="resultadosBusqueda.length > 0">
      <v-row
        no-gutters
        class=""
      >
        <v-col
          :cols="botonGrande ? 6 : 4"
          :md="botonGrande ? 4 : 3"
          :lg="botonGrande ? 3 : 2"
          v-for="p in resultadosBusqueda"
          :key="p.id"
          class="pa-1"
        >
          <producto-card
            :texto="p.nombreTpv"
            :stock="p.stockTotal"
            :esMenu="p.menu"
            :tieneOpciones="p.opciones"
            @accion="elegirProducto(p)"
            :cantidad="cantidadCarrito(p).noMenu"
            :precio="p.precioMostrar"
          />
        </v-col>
      </v-row>
    </template>
    <template v-else>
      <!--pasos-->
      <!--paso pidiendo categoría-->
      <template v-if="pasoPidiendo == 0">
        <v-row
          no-gutters
          class=""
        >
          <v-col
            :cols="botonGrande ? 6 : 4"
            :md="botonGrande ? 4 : 3"
            :lg="botonGrande ? 3 : 2"
            class="pa-1"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="nuevoProductoDialog = true"
                elevation="4"
                class="rounded-lg ficha-generica"
                :style="hover
                  ? 'cursor: pointer; border-color: #02fafd !important'
                  : 'border-color: #56a7a8 !important'
                  "
                :color="hover ? 'primary lighten-4' : 'primary borde-blanco'"
              >
                <v-responsive
                  :aspect-ratio="1.618"
                  class="align-center"
                >
                  <v-card-text
                    class="text-uppercase font-weight-black text-center px-0"
                    :class="botonGrande
                      ? $vuetify.breakpoint.mdAndDown
                        ? 'text-h6'
                        : 'text-h5'
                      : $vuetify.breakpoint.smAndDown || !!muchasOpciones
                        ? 'caption pa-1'
                        : ''
                      "
                    :style="$vuetify.breakpoint.smAndDown || !!muchasOpciones
                      ? 'line-height:1;'
                      : 'line-height:1.2;'
                      "
                  >
                    <v-col
                      :style="$vuetify.breakpoint.mdAndDown
                        ? 'max-height: 48px;'
                        : $vuetify.breakpoint.lgAndDown
                          ? 'max-height: 56px;'
                          : 'max-height: 120px;'
                        "
                      v-line-clamp="$vuetify.breakpoint.lgAndDown ? 3 : 7"
                      style="word-break: normal !important"
                    >
                      CREAR PRODUCTO
                    </v-col>
                  </v-card-text>
                </v-responsive>
              </v-card>
            </v-hover>
          </v-col>
          <v-col
            :cols="botonGrande ? 6 : 4"
            :md="botonGrande ? 4 : 3"
            :lg="botonGrande ? 3 : 2"
            class="pa-1"
            v-for="p in productosAntes"
            :key="'antes_' + p.id"
          >
            <producto-card
              :shake="!!editarProductoSistemaEditando"
              :stock="p.stockTotal"
              :texto="p.nombreTpv"
              :esMenu="p.menu"
              :tieneOpciones="p.opciones && p.opciones.length > 0"
              @accion="elegirProducto(p)"
              :cantidad="cantidadCarrito(p).noMenu"
              :cantidadMenu="cantidadCarrito(p).menu"
              :precio="p.precioMostrar"
              color="pink lighten-4"
              style="border-color: #56a7a8 !important"
            /></v-col>
          <v-col
            :cols="botonGrande ? 6 : 4"
            :md="botonGrande ? 4 : 3"
            :lg="botonGrande ? 3 : 2"
            class="pa-1"
            v-for="c in categoriasDefinitivas"
            :key="c.id"
          >
            <producto-card
              :shake="!!editarProductoSistemaEditando"
              :texto="c.nombre"
              @accion="elegirCategoria(c)"
            />
          </v-col>
          <v-col
            :cols="botonGrande ? 6 : 4"
            :md="botonGrande ? 4 : 3"
            :lg="botonGrande ? 3 : 2"
            class="pa-1"
            v-for="p in productosDespues"
            :key="'despues_' + p.id"
          >
            <producto-card
              :shake="!!editarProductoSistemaEditando"
              :stock="p.stockTotal"
              :texto="p.nombreTpv"
              :esMenu="p.menu"
              :tieneOpciones="p.opciones && p.opciones.length > 0"
              @accion="elegirProducto(p)"
              :cantidad="cantidadCarrito(p).noMenu"
              :cantidadMenu="cantidadCarrito(p).menu"
              :precio="p.precioMostrar"
              color="pink lighten-4"
              style="border-color: #56a7a8 !important"
            /></v-col>
        </v-row>
      </template>
      <!--paso pidiendo producto-->
      <template v-else-if="pasoPidiendo == 1">
        <v-row
          no-gutters
          class=""
        >
          <v-col
            class="pa-1"
            :cols="botonGrande ? 6 : 4"
            :md="botonGrande ? 4 : 3"
            :lg="botonGrande ? 3 : 2"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="pasoPidiendo--"
                elevation="4"
                class="rounded-lg ficha-generica"
                :style="hover ? 'cursor: pointer' : ''"
                :color="hover ? 'primary lighten-4' : 'primary borde-blanco'"
              >
                <v-responsive
                  :aspect-ratio="1.618"
                  class="align-center"
                >
                  <v-card-text
                    class="text-uppercase font-weight-bold text-center"
                    :class="$vuetify.breakpoint.smAndDown
                      ? 'caption'
                      : $vuetify.breakpoint.mdAndDown
                        ? 'body-2'
                        : 'body-1'
                      "
                    :style="$vuetify.breakpoint.smAndDown ? 'line-height:1;' : ''
                      "
                  >
                    <v-row justify="center">
                      <v-btn icon>
                        <v-icon
                          x-large
                          class="black--text"
                        >mdi-arrow-left-bold</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-responsive>
              </v-card>
            </v-hover>
          </v-col>
          <v-col
            :cols="botonGrande ? 6 : 4"
            :md="botonGrande ? 4 : 3"
            :lg="botonGrande ? 3 : 2"
            class="pa-1"
            v-for="p in productosDefinitivos"
            :key="p.id"
          >
            <producto-card
              :texto="p.nombreTpv"
              :esMenu="p.menu"
              :stock="p.stockTotal"
              :tieneOpciones="p.opciones && p.opciones.length > 0"
              :shake="!!editarProductoSistemaEditando"
              @accion="elegirProducto(p)"
              :cantidad="cantidadCarrito(p).noMenu"
              :cantidadMenu="cantidadCarrito(p).menu"
              :precio="p.precioMostrar"
            />
          </v-col>
        </v-row>
      </template>
      <template v-else-if="pasoPidiendo == 2 && !!menuPidiendo">
        <v-row
          no-gutters
          class=""
        >
          <!-- <v-col cols="12">
            <v-card-subtitle class="py-0">
              <v-row align="center">
                <v-col cols="12" v-if="editarProductoMenuObj">
                  <v-alert prominent type="warning">
                    Estás editando un menú {{ menuPidiendo.nombre }}</v-alert
                  >
                </v-col>
                <v-col cols="12" v-else>
                  <v-alert outlined type="warning" class="text-h6">
                    {{ menuPidiendo.nombre }} | TOTAL:
                    {{
                      $store.getters["pedidos/MENUS_EN_CARRITO"].filter(
                        (p) => p.menuId == menuPidiendo.id
                      ).length
                    }}
                    | COMPLETOS:
                    {{
                      $store.getters["pedidos/MENUS_EN_CARRITO"].filter(
                        (m) => m.grupos.filter((e) => !e.eleccion).length == 0
                      ).length
                    }}</v-alert
                  >
                </v-col>
              </v-row>
            </v-card-subtitle>
          </v-col> -->
          <v-col
            class="pa-1"
            :cols="botonGrande ? 6 : 4"
            :md="botonGrande ? 3 : 2"
          >
            <v-hover v-slot:default="{ hover }">
              <v-card
                @click="volver"
                elevation="4"
                class="rounded-lg ficha-generica"
                :style="hover ? 'cursor: pointer' : ''"
                :color="editarProductoMenuObj
                  ? 'grey'
                  : hover
                    ? 'primary lighten-4'
                    : 'primary borde-blanco'
                  "
              >
                <v-responsive
                  :aspect-ratio="1.618"
                  class="align-center"
                >
                  <v-card-text
                    class="text-uppercase font-weight-bold text-center"
                    :class="$vuetify.breakpoint.smAndDown
                      ? 'caption'
                      : $vuetify.breakpoint.mdAndDown
                        ? 'body-2'
                        : 'body-1'
                      "
                    :style="$vuetify.breakpoint.smAndDown ? 'line-height:1;' : ''
                      "
                  >
                    <v-row justify="center">
                      <v-btn icon>
                        <v-icon
                          x-large
                          class="black--text"
                        >mdi-arrow-left-bold</v-icon>
                      </v-btn>
                    </v-row>
                  </v-card-text>
                </v-responsive>
              </v-card>
            </v-hover>
          </v-col>
          <!-- cabecera grupos menu-->
          <v-col
            :cols="botonGrande ? 6 : 4"
            :md="botonGrande ? 4 : 3"
            :lg="botonGrande ? 3 : 2"
            class="pa-1"
            v-for="(panel, i) in menuPidiendo.grupos"
            :key="panel.id"
          >
            <producto-card
              :texto="panel.nombreMostrar"
              @accion="pasoPidiendoMenu = i + 1"
              :esMenuGrupo="true"
              :esMenuSelect="pasoPidiendoMenu === i + 1"
            />
          </v-col>
          <!-- productos menu-->
        </v-row>
        <v-divider class="my-3" />
        <v-row
          no-gutters
          class=""
        >
          <v-col
            :cols="botonGrande ? 6 : 4"
            :md="botonGrande ? 4 : 3"
            :lg="botonGrande ? 3 : 2"
            class="pa-1"
            v-for="p in menuPidiendo.grupos[pasoPidiendoMenu - 1]
              .productosGrupo"
            :key="p.id"
          >
            <producto-card
              :seleccionado="editarProductoMenuObj &&
                editarProductoMenuObj.grupos[pasoPidiendoMenu - 1] &&
                editarProductoMenuObj.grupos[pasoPidiendoMenu - 1].eleccion &&
                editarProductoMenuObj.grupos[pasoPidiendoMenu - 1].eleccion
                  .id == p.id
                "
              :texto="p.nombre"
              :cantidad="cantidadCarritoMenuEligiendo(p)"
              @accion="elegirProducto(p)"
            />
          </v-col>
        </v-row>
      </template>
    </template>
    <!--dialogo opciones productos -->
    <v-dialog
      v-if="!!productoPidiendo && !!opcionesProductoDialogo"
      v-model="opcionesProductoDialogo"
      content-class="elevation-0"
      overlay-opacity="0.90"
      persistent
      :fullscreen="$vuetify.breakpoint.smAndDown"
      scrollable
    >
      <v-card :rounded="$vuetify.breakpoint.smAndDown ? '0' : 'lg'">
        <v-card-title class="rounded-t-lg pa-0">
          <v-list
            class="py-0 transparent"
            style="width: 100%"
          >
            <v-list-item>
              <v-list-item-content>
                <v-list-item-title class="title">{{
                  nombreProducto
                }}</v-list-item-title>
                <v-list-item-subtitle>{{ precioProductoSeleccionado }} €</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon class="my-2 mx-2">
                <template v-if="$vuetify.breakpoint.mdAndUp">
                  <v-btn
                    v-if="!editarOpcionSistemaPanel"
                    fab
                    :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                    :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                    color="primary"
                    @click="editarOpcionSistemaPanel = panelActual.id"
                  ><v-icon
                      :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                      color="black"
                    >mdi-pencil</v-icon></v-btn>
                  <v-btn
                    v-else
                    fab
                    :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                    :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                    color="success"
                    @click="editarOpcionSistemaPanel = null"
                  ><v-icon
                      :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                      color="white"
                    >mdi-check</v-icon></v-btn>
                </template>
              </v-list-item-icon>

              <v-list-item-icon class="my-2 mx-2">
                <v-badge
                  :content="productoPidiendo.opciones.length"
                  :value="productoPidiendo.opciones.length > 0"
                  color="primary"
                  light
                  large
                  class="black--text"
                  overlap
                >
                  <v-btn
                    fab
                    :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                    :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                    color="primary"
                    :outlined="!mostrarTodasOpciones"
                    @click="mostrarTodasOpciones = !mostrarTodasOpciones"
                  ><v-icon
                      :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                      color="black"
                    >{{ mostrarTodasOpciones ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon></v-btn></v-badge>
              </v-list-item-icon>

              <v-list-item-icon class="my-2">
                <v-btn
                  fab
                  :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                  :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                  color="primary"
                  @click="opcionesProductoDialogo = false"
                ><v-icon
                    :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                    color="black"
                  >mdi-close</v-icon></v-btn>
              </v-list-item-icon>
            </v-list-item>
          </v-list>
        </v-card-title>
        <!--stepper-->
        <v-card-text class="pa-0 blue-grey lighten-5">
          <!-- :alt-labels="$vuetify.breakpoint.smAndDown" -->
          <v-stepper
            v-model="pasoPidiendoOpciones"
            class="transparent stepper--sticky-header"
            elevation="0"
          >
            <v-stepper-header
              class="grey lighten-5 elevation-0"
              style="align-content: center"
              :style="$vuetify.breakpoint.smAndDown ? 'height:26px;' : 'height:36px;'
                "
            >
              <template v-for="(panel, i) in cabeceraStepperOpciones">
                <v-stepper-step
                  :key="panel.id"
                  :step="i + 1"
                  :style="'max-width: ' + 100 / opciones.length + '%;'"
                  :class="$vuetify.breakpoint.smAndDown ? 'py-4' : 'py-1'"
                  class=""
                >
                  {{ panel.nombreMostrar }}
                </v-stepper-step>
                <v-spacer :key="`spacer_${i}`" />
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                v-for="(panel, i) in opciones"
                :step="i + 1"
                :key="i"
                class="pa-0 overflow-y-auto"
                style="min-height: 440px"
              >
                <v-card-title
                  v-if="$vuetify.breakpoint.smAndDown"
                  class="body-1 font-weight-bold"
                >
                  {{ panel.nombreMostrar }}
                  <v-spacer></v-spacer>
                  <template v-if="panelActual.validacion.msj == 'Correcto'">
                    <v-icon color="success">mdi-check-bold</v-icon>
                  </template>
                  <span
                    v-else-if="panelActual.validacion.msj != 'Seleccione una opción'
                      "
                    :class="panelActual.validacion.msj > 0
                      ? 'grey--text'
                      : 'warning--text'
                      "
                  >
                    {{ panelActual.validacion.msj }}
                  </span>
                </v-card-title>

                <v-card-text>
                  <!-- Eligiendo sin editar-->
                  <v-row
                    class="pa-1"
                    v-if="!editarOpcionSistemaPanel"
                  >
                    <v-col
                      :cols="botonGrande ? 6 : 4"
                      :md="botonGrande ? 4 : 3"
                      :lg="botonGrande ? 3 : 2"
                      v-if="!(pasoPidiendoOpciones == 1) && !paginarOpciones"
                      class="pa-1 custom7cols"
                    >
                      <v-card
                        elevation="4"
                        class="rounded-lg ficha-generica"
                        color="pink lighten-4"
                        :disabled="pasoPidiendoOpciones == 1"
                        @click="pasoAtras"
                      >
                        <v-responsive
                          :aspect-ratio="1.618"
                          class="align-center text-center"
                        >
                          <v-icon> mdi-arrow-left-bold </v-icon>
                        </v-responsive></v-card>
                    </v-col>
                    <v-col
                      :cols="botonGrande ? 6 : 4"
                      :md="botonGrande ? 4 : 3"
                      :lg="botonGrande ? 3 : 2"
                      v-if="paginarOpciones"
                      class="pa-1 custom7cols"
                    >
                      <v-card
                        elevation="4"
                        class="rounded-lg ficha-generica"
                        color="blue lighten-4"
                        :disabled="paginarOpcionesPagina == 0"
                        @click="paginarOpcionesPasoMetodo('atras')"
                      >
                        <v-responsive
                          :aspect-ratio="1.618"
                          class="align-center text-center"
                        >
                          <v-icon> mdi-arrow-left-bold </v-icon>
                        </v-responsive></v-card>
                    </v-col>
                    <v-col
                      cols="4"
                      md="3"
                      lg="2"
                      class="pa-1 custom7cols"
                      v-for="(opcion, iopcion) in opcionesCorregidas(
                        panel.opciones,
                        true
                      )"
                      :key="opcion.id"
                    >
                      <producto-card
                        :shake="!!editarOpcionSistemaPanel"
                        :texto="opcion.nombreTpv"
                        @accion="elegirOpcion(opcion, panel)"
                        :precio="opcion.precioCobrarMostrar > 0
                          ? '+' + opcion.precioCobrarMostrar
                          : null
                          "
                        :muchasOpciones="panel.opciones.length > 20"
                        :seleccionado="opcion.cantidad > 0 ? true : false"
                        v-long-press="1500"
                      />
                    </v-col>
                    <v-col
                      cols="4"
                      md="3"
                      lg="2"
                      class="pa-1 custom7cols"
                      v-if="!(!panelActual.validacion.ok || !pasoAdelanteBool()) &&
                        !paginarOpciones
                        "
                    >
                      <v-card
                        elevation="4"
                        class="rounded-lg ficha-generica"
                        color="pink lighten-4"
                        :disabled="!panelActual.validacion.ok || !pasoAdelanteBool()
                          "
                        @click="pasoAdelante()"
                      >
                        <v-responsive
                          :aspect-ratio="1.618"
                          class="align-center text-center"
                        >
                          <v-icon> mdi-arrow-right-bold </v-icon>
                        </v-responsive></v-card>
                    </v-col>
                    <v-col
                      :cols="botonGrande ? 6 : 4"
                      :md="botonGrande ? 4 : 3"
                      :lg="botonGrande ? 3 : 2"
                      v-else-if="paginarOpciones"
                      class="pa-1 custom7cols"
                    >
                      <v-card
                        elevation="4"
                        class="rounded-lg ficha-generica"
                        color="blue lighten-4"
                        :disabled="paginarOpcionesPagina == paginarOpcionesNumeroPaginas
                          "
                        @click="paginarOpcionesPasoMetodo('delante')"
                      >
                        <v-responsive
                          :aspect-ratio="1.618"
                          class="align-center text-center"
                        >
                          <v-icon> mdi-arrow-right-bold </v-icon>
                        </v-responsive></v-card>
                    </v-col>
                  </v-row>
                  <!-- Editando opciones-->

                  <v-row>
                    <v-col
                      cols="4"
                      md="3"
                      lg="2"
                      class="pa-1 custom7cols"
                      v-for="(
                        opcion, iopcion
                      ) in editarOpcionSistemaOpcionesMostrar"
                      :key="opcion.id"
                    >
                      <producto-card
                        :shake="!!editarOpcionSistemaPanel"
                        :texto="opcion.id ? opcion.nombreTpv : 'Nuevo'"
                        @accion="pulsacionLargaOpcion(opcion, panel, iopcion)"
                        :precio="opcion.precioCobrarMostrar > 0
                          ? '+' + opcion.precioCobrarMostrar
                          : null
                          "
                        :muchasOpciones="panel.opciones.length > 20"
                        :seleccionado="opcion.cantidad > 0 ? true : false"
                        v-long-press="1500"
                        :color="!opcion.id
                          ? 'yellow lighten-2'
                          : opcion.ocultar
                            ? 'grey lighten-1'
                            : 'primary lighten-4'
                          "
                      />
                    </v-col>
                  </v-row>
                </v-card-text>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions
          v-if="!editarOpcionSistemaPanel"
          :class="$vuetify.breakpoint.mdAndUp ? '' : ''"
          class="pa-2 blue-grey lighten-5 rounded-b-lg"
        >
          <v-row
            no-gutters
            class=""
            justify="end"
            style="width: 100%"
          >
            <v-col
              :cols="botonGrande ? 6 : 3"
              :md="botonGrande ? 4 : 3"
              :lg="botonGrande ? 3 : 2"
              class="pr-2"
            >
              <v-btn
                large
                block
                class="black--text"
                :disabled="pasoPidiendoOpciones == 1"
                @click="pasoAtras"
                color="primary"
              >
                <v-icon> mdi-arrow-left-bold </v-icon>
              </v-btn>
            </v-col>
            <v-col
              :cols="botonGrande ? 6 : 3"
              :md="botonGrande ? 4 : 3"
              :lg="botonGrande ? 3 : 2"
              class="pr-2"
            >
              <v-btn
                large
                block
                class="black--text"
                :disabled="!panelActual.validacion.ok || !pasoAdelanteBool()"
                @click="pasoAdelante()"
                color="primary"
              >
                <v-icon> mdi-arrow-right-bold </v-icon>
              </v-btn>
            </v-col>
            <v-spacer />

            <v-col
              cols="6"
              md="3"
              class=""
            >
              <v-btn
                large
                block
                class="black--text"
                :class="$vuetify.breakpoint.mdAndUp ? '' : ''"
                color="primary"
                style="max-width: 100%"
                @click="addProductoBtn(false)"
                :disabled="!addOpcionValido"
              >Añadir
              </v-btn>
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-if="productoPidiendo"
      v-model="productoPesoDialogo"
      max-width="800"
      content-class="blue-grey lighten-5 rounded-lg"
      persistent
    >
      <v-card
        class="border"
        color="transparent"
      >
        <v-card-title>
          <span
            class="font-weight-black text-uppercase"
            :class="$vuetify.breakpoint.mdAndUp ? '' : 'caption'"
          >
            {{ nombreProducto }}
          </span>
          <v-spacer />
          <span :class="$vuetify.breakpoint.mdAndUp ? '' : 'v-card__subtitle pa-0'">
            {{ precioPesoTotal }}
          </span>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                readonly
                label="PESO (Kg)"
                :value="pesoEnVivo"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                readonly
                label="PRECIO (€/Kg)"
                :value="precioPesoBase"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.mdAndUp ? 'py-4' : 'py-2 flex-column'">
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            color="warning"
            @click="productoPesoDialogo = false"
            class="rounded-lg"
          >Cancelar</v-btn>
          <v-spacer />
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            class="black--text rounded-lg"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-0 mt-2'"
            color="primary"
            @click="addProductoPeso"
          >Aceptar y añadir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="nuevoProductoDialog"
      max-width="800"
      content-class="nuevo-producto-dialogo blue-grey lighten-5 rounded-lg"
      persistent
    >
      <v-card color="blue-grey lighten-5">
        <v-card-title class="white py-3 pr-3">
          <v-row
            align="center"
            no-gutters
          >
            <v-col
              cols="auto"
              md="6"
            >
              <h2
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'caption'"
                class="font-weight-black text-uppercase"
              >
                Nuevo producto
              </h2>
            </v-col>
            <!-- Por peso. Activarlo de manera dinámica-->
            <v-col
              cols="auto"
              md="3"
              v-if="false"
            >
              <v-checkbox
                hide-details
                class="mt-0 pt-0"
                :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-4'"
                v-model="nuevoProducto.porPeso"
              >
                <template v-slot:label>
                  <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'caption ml-n2'
                    ">
                    Por peso
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-col
              cols="auto"
              md="3"
              v-if="configuracionTpv.productosGestiona"
            >
              <v-checkbox
                hide-details
                class="mt-0 pt-0"
                :class="$vuetify.breakpoint.mdAndUp ? '' : 'ml-4'"
                v-model="nuevoProducto.guardar"
              >
                <template v-slot:label>
                  <span :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'caption ml-n2'
                    ">
                    Guardar
                  </span>
                </template>
              </v-checkbox>
            </v-col>
            <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
            <v-col
              :cols="configuracionTpv.productosGestiona ? '3' : '6'"
              class="text-right"
            >
              <v-btn
                fab
                :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                color="primary"
                @click="nuevoProductoDialog = false"
                top
                right
              >
                <v-icon
                  :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                  color="black"
                >mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="pt-4">
          <v-row>
            <v-col md="6">
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="nuevoProducto.nombre"
                label="Introduce nombre"
                outlined
                hide-details
                ref="nuevoProductoNombre"
                @focus="setNuevoProductoCampoCambiando('nombre')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="!nuevoProducto.porPeso"
            >
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="nuevoProducto.precio"
                step="0.1"
                label="Introduce precio"
                outlined
                hide-details
                @focus="setNuevoProductoCampoCambiando('precio')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-else
            >
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                label="PRECIO (€/Kg)"
                v-model="nuevoProducto.precioBase"
                @focus="setNuevoProductoCampoCambiando('precioBase')"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-if="!nuevoProducto.porPeso"
            >
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="nuevoProducto.cantidad"
                type="number"
                step="1"
                label="Cantidad"
                outlined
                hide-details
                @focus="setNuevoProductoCampoCambiando('cantidad')"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="6"
              v-else
            >
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                readonly
                label="PESO (Kg)"
                :value="pesoEnVivo"
                outlined
                hide-details
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="nuevoProducto.impuesto"
                :items="$store.getters['impuestosSelect']"
                label="Impuesto"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
          <v-row v-if="nuevoProducto.guardar">
            <v-col
              cols="12"
              md="6"
            >
              <v-select
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="nuevoProducto.categoria"
                :items="categorias"
                item-text="nombre"
                item-value="id"
                label="Categoría"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          class="pb-4"
          v-if="$store.state.necesarioTeclado"
        >
          <div
            class="tecladoNuevoProducto"
            v-show="crearNuevoProductoTipoTeclado == 'completo'"
          />
          <template v-if="$vuetify.breakpoint.mdAndUp">
            <v-row
              justify="center"
              v-show="crearNuevoProductoTipoTeclado == 'numerico'"
            >
              numerico
              <v-col
                cols="12"
                sm="6"
                md="5"
              >
                <KeyboardNumerico
                  keyboardClass="keyboard-producto-nuevo"
                  ref="keyboardRefNumerico"
                  noDividir
                  @onChange="onChangeTecladoNuevoProductoNumerico"
                  @onKeyPress="onKeyPressTecladoNuevoProductoNumerico"
                  :input="inputTecladoNumerico"
                />
              </v-col>
            </v-row>
          </template>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.mdAndUp ? 'py-4' : 'py-2 flex-column'">
          <v-spacer />
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            :disabled="nuevoProductoDisabled"
            class="black--text rounded-lg"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-0 mt-2'"
            color="primary"
            @click="addNuevoProducto"
          >Aceptar y añadir</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editarProductoSistemaDialog"
      max-width="800"
      content-class="nuevo-producto-dialogo blue-grey lighten-5 rounded-lg"
      persistent
    >
      <v-card color="blue-grey lighten-5">
        <v-card-title class="white py-3 pr-3">
          <v-row
            align="center"
            no-gutters
          >
            <v-col
              cols="auto"
              md="6"
            >
              <h2
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'caption'"
                class="font-weight-black text-uppercase"
              >
                Editar producto
              </h2>
            </v-col>


            <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
            <v-col
              cols="6"
              class="text-right"
            >
              <v-btn
                fab
                :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                color="primary"
                @click="editarProductoSistemaDialog = false"
                top
                right
              >
                <v-icon
                  :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                  color="black"
                >mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="pt-4"
          v-if="editarProductoSistemaObj"
        >
          <v-row>
            <v-col md="6">
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="editarProductoSistemaObj.nombre"
                label="Nombre"
                outlined
                hide-details
                ref="nuevoProductoNombre"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                label="Precio"
                type="number"
                v-model="editarProductoSistemaObj.precio"
                outlined
                hide-details
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                class="white headline font-weight-bold centered-input rounded-lg"
                disabled
                v-model="editarProductoSistemaObj.impuesto"
                :items="$store.getters['impuestosSelect']"
                label="Impuesto"
                outlined
                hide-details
              />
            </v-col>

            <v-col
              cols="12"
              md="6"
            >
              <v-select
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="editarProductoSistemaObj.categoria"
                :items="categorias"
                item-text="nombre"
                item-value="id"
                label="Categoría"
                outlined
                hide-details
              /> </v-col>

            <v-col
              md="6"
              v-if="editarProductoSistemaObj.secreto"
            >
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="editarProductoSistemaObj.secreto.fechaCaducidad"
                type="date"
                label="Fecha caducidad"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col md="6">
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="editarProductoSistemaObj.codigoLector"
                label="Código barras"
                outlined
                hide-details
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              v-if="editarProductoSistemaObj.stock"
            >

              <v-row
                dense
                class="bg-primary mb-3"
                justify="center"
              >
                <v-col
                  cols="12"
                  class="d-flex justify-space-around"
                >
                  <v-btn
                    v-if="accionStock == null"
                    color="primary"
                    @click="accionStock = 'moverStock'"
                    class="black--text"
                  >Mover Stock <v-icon>mdi-swap-vertical</v-icon></v-btn>
                  <v-btn
                    v-if="accionStock == null"
                    @click="accionStock = 'addStock'"
                    color="primary"
                    class="black--text"
                  >Añadir Stock <v-icon>mdi-plus</v-icon></v-btn>
                </v-col>
              </v-row>
              <v-row
                v-if="accionStock == 'moverStock'"
                class="mb-3"
                justify="center"
                align="center"
              >
                <v-col
                  cols="6"
                  align-self="center"
                >

                  <v-btn
                    class="mr-3"
                    :loading="loading"
                    icon
                    outlined
                    color="black"
                    @click="accionStock = null"
                  ><v-icon>mdi-arrow-left-bold</v-icon></v-btn> <span class="text-h6 mt-2">Mover Stock</span>
                </v-col>
                <v-col
                  align="right"
                  cols="6"
                >
                  <v-btn
                    class="ml-5 black--text"
                    color="primary"
                    :loading="loading"
                    @click="confirmarMovimientoStock"
                  >
                    Confirmar movimiento<v-icon
                      class="ml-3"
                      color="success"
                    >mdi-check</v-icon></v-btn>



                </v-col>
                <v-col cols="5">
                  <v-select
                    class="white headline font-weight-bold centered-input rounded-lg"
                    label="Origen"
                    v-model="moverStockObj.origen"
                    :items="$store.getters['ALMACENES']"
                    item-text="nombre"
                    item-value="id"
                    outlined
                    hide-details=""
                  />
                </v-col>
                <v-col cols="5">

                  <v-select
                    class="white headline font-weight-bold centered-input rounded-lg"
                    label="Destino"
                    v-model="moverStockObj.destino"
                    item-text="nombre"
                    item-value="id"
                    :items="$store.getters['ALMACENES'].filter(a => a.id !== moverStockObj.origen)"
                    outlined
                    hide-details=""
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    class="white headline font-weight-bold centered-input rounded-lg"
                    label="Cantidad"
                    type="number"
                    step="1"
                    :max="editarProductoSistemaObj.stock[moverStockObj.origen]"
                    min="1"
                    hide-details
                    @change="($e) => parseInt($e) > editarProductoSistemaObj.stock[moverStockObj.origen] ? moverStockObj.cantidad = editarProductoSistemaObj.stock[moverStockObj.origen] : ''"
                    v-model="moverStockObj.cantidad"
                    outlined
                  />
                </v-col>
              </v-row>
              <v-row
                v-else-if="accionStock == 'addStock'"
                class="mb-3"
                justify="center"
                align="center"
              >
                <v-col
                  cols="6"
                  align-self="center"
                >

                  <v-btn
                    class="mr-3"
                    :loading="loading"
                    icon
                    outlined
                    color="black"
                    @click="accionStock = null"
                  ><v-icon>mdi-arrow-left-bold</v-icon></v-btn> <span class="text-h6 mt-2">Añadir Stock</span>
                </v-col>
                <v-col
                  align="right"
                  cols="6"
                >
                  <v-btn
                    class="ml-5 black--text"
                    color="primary"
                    :loading="loading"
                    @click="confirmarAddStock"
                  >
                    Añadir stock<v-icon
                      class="ml-3"
                      color="success"
                    >mdi-check</v-icon></v-btn>



                </v-col>
                <v-col cols="5">

                </v-col>
                <v-col cols="8">
                  <v-select
                    class="white headline font-weight-bold centered-input rounded-lg"
                    label="Tienda / Almacén"
                    v-model="addStockObj.destino"
                    :items="$store.getters['ALMACENES']"
                    item-text="nombre"
                    item-value="id"
                    outlined
                    hide-details=""
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    class="white headline font-weight-bold centered-input rounded-lg"
                    label="Añadir"
                    type="number"
                    step="1"
                    min="0"
                    hide-details
                    @input="addStockObj.cantidad = parseInt(addStockObj.cantidadInicial) + parseInt(addStockObj.cantidadAdd)"
                    v-model="addStockObj.cantidadAdd"
                    outlined
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    class="white headline font-weight-bold centered-input rounded-lg"
                    label="Total"
                    type="number"
                    step="1"
                    :min="addStockObj.cantidadInicial"
                    hide-details
                    @input="addStockObj.cantidadAdd = parseInt(addStockObj.cantidad) - parseInt(addStockObj.cantidadInicial)"
                    v-model="addStockObj.cantidad"
                    outlined
                  />
                </v-col>
              </v-row>

              <v-simple-table>
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th class="text-left">
                        Almacén
                      </th>
                      <th class="text-center">
                        Cantidad
                      </th>
                      <th
                        class="text-center"
                        v-if="accionStock !== null"
                      >
                        Habrá
                      </th>
                    </tr>
                  </thead>

                  <tbody>
                    <tr
                      v-for="  alm   in   $store.getters['ALMACENES']  "
                      :key="alm.id"
                    >
                      <td class="subtitle-1">{{ alm.nombre }}</td>
                      <td class="text-center">
                        <v-icon v-if="isNaN(editarProductoSistemaObj.stock[alm.id])">mdi-help-box</v-icon>
                        <v-chip
                          label
                          v-else
                          color="primary"
                          class="font-weight-black black--text text-center"
                        >{{ editarProductoSistemaObj.stock[alm.id] }}</v-chip>
                      </td>
                      <td
                        class="text-center"
                        v-if="accionStock == 'moverStock'"
                      >
                        <template v-if="moverStockObj.destino == alm.id">
                          <v-icon color="success">mdi-arrow-up-bold</v-icon>
                          <v-chip
                            label
                            outlined
                            color="success"
                            class="font-weight-black black--text text-center"
                          >{{ (isNaN(editarProductoSistemaObj.stock[alm.id]) ? 0 : editarProductoSistemaObj.stock[alm.id])
                            + parseInt(moverStockObj.cantidad) }}</v-chip>
                        </template>
                        <template v-else-if="moverStockObj.origen == alm.id">
                          <v-icon color="red">mdi-arrow-down-bold</v-icon>
                          <v-chip
                            label
                            outlined
                            color="red"
                            class="font-weight-black black--text text-center"
                          >{{
                            (isNaN(editarProductoSistemaObj.stock[alm.id]) ? 0 : editarProductoSistemaObj.stock[alm.id]) -
                            parseInt(moverStockObj.cantidad) }}</v-chip>
                        </template>
                      </td>
                      <td
                        class="text-center"
                        v-else-if="accionStock == 'addStock'"
                      >
                        <template v-if="addStockObj.destino == alm.id">
                          <v-icon
                            v-if="parseInt(addStockObj.cantidadAdd) > 0"
                            color="success"
                          >mdi-arrow-up-bold</v-icon>
                          <v-icon
                            v-else
                            color="success"
                          >mdi-equal</v-icon>

                          <v-chip
                            label
                            outlined
                            color="success"
                            class="font-weight-black black--text text-center"
                          >{{ (isNaN(editarProductoSistemaObj.stock[alm.id]) ? 0 : editarProductoSistemaObj.stock[alm.id])
                            + parseInt(addStockObj.cantidadAdd) }}</v-chip>
                        </template>
                        <template v-else>
                          <v-chip
                            label
                            outlined
                            color="info"
                            class="font-weight-black black--text text-center"
                          >{{
                            (isNaN(editarProductoSistemaObj.stock[alm.id]) ? 0 : editarProductoSistemaObj.stock[alm.id])
                          }}</v-chip>
                        </template>



                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>

            </v-col>
            <!-- <v-col md="6">
                <v-text-field
                  class="white headline font-weight-bold centered-input rounded-lg"
                  v-model="editarProductoSistemaObj.nombre"
                  label="Nombre"
                  outlined
                  hide-details
                  ref="nuevoProductoNombre"
                ></v-text-field>
              </v-col> -->
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.mdAndUp ? 'py-4' : 'py-2 flex-column'">
          <v-spacer />
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            :disabled="nuevoProductoSistemaDisabled"
            class="black--text rounded-lg"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-0 mt-2'"
            color="primary"
            @click="editarProductoSistemaGuardar"
            :loading="editarSistemaLoading"
          >Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="editarOpcionSistemaDialog"
      max-width="800"
      content-class="nuevo-producto-dialogo blue-grey lighten-5 rounded-lg"
      persistent
    >
      <v-card color="blue-grey lighten-5">
        <v-card-title class="white py-3 pr-3">
          <v-row
            align="center"
            no-gutters
          >
            <v-col
              cols="auto"
              md="6"
            >
              <h2
                :class="$vuetify.breakpoint.mdAndUp ? 'text-h6' : 'caption'"
                class="font-weight-black text-uppercase"
              >
                Editar opción
              </h2>
            </v-col>
            <!-- Por peso. Activarlo de manera dinámica-->

            <v-spacer v-if="$vuetify.breakpoint.smAndDown"></v-spacer>
            <v-col
              cols="6"
              class="text-right"
            >
              <v-btn
                fab
                :height="$vuetify.breakpoint.smAndDown ? 48 : 68"
                :width="$vuetify.breakpoint.smAndDown ? 48 : 68"
                color="primary"
                @click="editarOpcionSistemaDialog = false"
                top
                right
              >
                <v-icon
                  :size="$vuetify.breakpoint.smAndDown ? 36 : 44"
                  color="black"
                >mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          class="pt-4"
          v-if="editarOpcionSistemaObj"
        >
          <v-row>
            <v-col md="5">
              <v-text-field
                class="white headline font-weight-bold centered-input rounded-lg"
                v-model="editarOpcionSistemaObj.nombreMostrar"
                label="Nombre"
                outlined
                hide-details
                ref="nuevoProductoNombre"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                step="0.5"
                class="white headline font-weight-bold centered-input rounded-lg"
                label="Precio"
                type="number"
                v-model="editarOpcionSistemaObj.precio"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-text-field
                type="number"
                min="1"
                :max="editarOpcionSistemaOpcionesMostrar.length"
                class="white headline font-weight-bold centered-input rounded-lg"
                label="Orden"
                v-model="editarOpcionSistemaObj.orden"
                outlined
                hide-details
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              md="2"
            >
              <v-checkbox
                class="white headline font-weight-bold centered-input rounded-lg"
                label="Ocultar"
                v-model="editarOpcionSistemaObj.ocultar"
                outlined
                hide-details
              />
            </v-col>
          </v-row>
        </v-card-text>

        <v-divider></v-divider>
        <v-card-actions :class="$vuetify.breakpoint.mdAndUp ? 'py-4' : 'py-2 flex-column'">
          <v-spacer />
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            class="black--text rounded-lg"
            :class="$vuetify.breakpoint.mdAndUp ? 'ml-4' : 'ml-0 mt-2'"
            color="primary"
            @click="editarOpcionSistemaGuardar"
            :loading="editarSistemaLoading"
          >Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!--dialogo menu, no se usa, se usa en la parte izuiqerda en el pasoPidiendo 2-->
    <v-dialog
      v-model="menuDialog"
      max-width="800"
      v-if="!!menuPidiendo"
    >
      <v-card>
        <v-card-title>MENU {{ menuPidiendo.nombre }}</v-card-title>
        <v-card-text>
          <v-stepper
            non-linear
            v-model="pasoPidiendoMenu"
            class="transparent"
            elevation="0"
          >
            <v-stepper-header
              class="grey lighten-5 elevation-0"
              style="align-content: center"
            >
              <template v-for="(  panel, i  ) in   menuPidiendo.grupos  ">
                <v-stepper-step
                  editable
                  :key="i"
                  :step="i + 1"
                  :style="'max-width: ' + 100 / menuPidiendo.grupos.length + '%;'
                    "
                >
                  {{ panel.nombreMostrar }}
                </v-stepper-step>
                <v-spacer :key="`spacer_${i}`" />
              </template>
            </v-stepper-header>
            <v-stepper-items>
              <v-stepper-content
                v-for="(  panel, i  ) in   menuPidiendo.grupos  "
                :key="i"
                :step="i + 1"
              >
                <v-row class="pa-2 pa-md-0 mt-n4 mx-n4">
                  <v-col
                    cols="6"
                    md="3"
                    v-for="  producto   in   panel.productosGrupo  "
                    :key="producto.id"
                    class="pa-2 pa-md-3"
                  >
                    <v-hover v-slot:default="{ hover }">
                      <v-card
                        @click="elegirProducto(producto)"
                        elevation="4"
                        class="rounded-lg ficha-generica"
                        :style="hover ? 'cursor: pointer' : ''"
                        :color="producto.cantidad > 0 ? 'primary' : 'white'"
                      >
                        <v-responsive
                          :aspect-ratio="1.618"
                          class="align-center"
                        >
                          <v-card-text
                            class="text-uppercase font-weight-black text-center"
                            :class="$vuetify.breakpoint.mdAndDown
                              ? 'body-2'
                              : 'body-1'
                              "
                          >
                            <v-row justify="center">
                              {{ producto.nombreTpv }}
                            </v-row>
                            <v-row
                              class="mt-5"
                              justify="center"
                              v-if="!!producto.precioCobrarMostrar &&
                                producto.precioCobrarMostrar != '0.00'
                                "
                            >
                              <v-chip
                                small
                                class="grey--text text--darken-4"
                                :color="producto.cantidad > 0 ? 'white' : 'primary'
                                  "
                              >+ {{ producto.precioCobrarMostrar }} €</v-chip>
                            </v-row>
                          </v-card-text>
                        </v-responsive>
                      </v-card></v-hover></v-col></v-row>
              </v-stepper-content>
            </v-stepper-items>
          </v-stepper>
        </v-card-text>
        <v-card-actions>
          <v-btn
            :large="$vuetify.breakpoint.mdAndUp"
            :block="$vuetify.breakpoint.smAndDown"
            color="warning"
            @click="menuDialog = false"
            class="rounded-lg"
          >Cancelar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import KeyboardNumerico from "./KeyboardNumerico";
import Keyboard from "simple-keyboard";
import "simple-keyboard/build/css/index.css";
import { mapState, mapGetters } from "vuex";
import { key } from "firebase-key";

import moment from "moment";
import { useCounter, useIdle } from '@vueuse/core'

import {
  mostrarIdioma,
  mostrarMoneda,
  isFloat,
  chunkArray,
  parsePrecios,
  redondeo,
} from "@/utils/helper.utils.js";
import ProductoCard from "./ProductoCard.vue";
import columnas from "@/mixins/columnas.js";

export default {
  mixins: [columnas],

  components: { KeyboardNumerico, ProductoCard },
  data() {
    return {
      loading: false,
      moverStockObj: {
        origen: null,
        destino: null,
        cantidad: 0,
        cantidadInicial: 0
      },
      addStockObj: {
        destino: null,
        cantidad: 0,
        cantidadInicial: 0,
        cantidadAdd: 0,
      },

      // mostrarTodasOpciones: false, //para mostrar todos los paneles, aunque no sean obligatorio
      accionStock: null,
      paginarOpciones: false,
      paginarOpcionesPagina: 1,
      paginarOpcionesNumeroPaginas: 0, //número de páginas para las opciones. Si > 0 entonces hay que paginar.
      paginarOpcionesOpcionesTotal: 1,

      editarSistemaLoading: false,
      editarOpcionSistemaPanel: null,
      editarProductoSistemaDialog: false,
      editarProductoSistemaObj: null,
      editarOpcionSistemaDialog: false,
      editarOpcionSistemaObj: null,
      editarOpcionSistemaObjAntes: null,

      addOpcionDisponible: false,
      inputTecladoNumerico: "",
      loadingBuscar: false,
      buscar: "",
      //pasoPidiendoOpciones: 1,
      pasoPidiendoOpcionesAnterior: [],
      // pasoPidiendo: 0,
      //opcionesProductoDialogo: false,
      productoPesoDialogo: false,
      nuevoProductoDialog: false,
      nuevoProductoCampoCambiando: null,
      nuevoProductoTeclado: null,
      nuevoProducto: {
        nombre: "",
        precio: 0,
        cantidad: 1,
        impuesto: "",
        porPeso: false,
        precioBase: 0,
        peso: 0,
        guardar: false,
      },
      pasoPidiendoMenu: 1,
      lastActive: null,
      idle: null,
    };
  },
  watch: {
    editarProductoSistemaDialog(v) {
      if (!v) {
        this.accionStock = null;
        this.moverStockObj = {
          origen: null,
          destino: null,
          cantidad: 0,
          cantidadInicial: 0
        };
        this.addStockObj = {
          destino: null,
          cantidad: 0,
          cantidadInicial: 0,
          cantidadAdd: 0
        };
      }
    },
    "moverStockObj.origen": function (v) {
      this.moverStockObj.destino = this.$store.getters['ALMACENES'].filter(r => r.id != this.moverStockObj.origen)[0].id
    },
    "addStockObj.destino": function (v) {
      this.addStockObj.cantidad = !isNaN(this.editarProductoSistemaObj.stock[this.addStockObj.destino]) ? this.editarProductoSistemaObj.stock[this.addStockObj.destino] : 0;
      this.addStockObj.cantidadInicial = !isNaN(this.editarProductoSistemaObj.stock[this.addStockObj.destino]) ? this.editarProductoSistemaObj.stock[this.addStockObj.destino] : 0;
      this.addStockObj.cantidadAdd = 0
    },
    accionStock(v) {
      if (v == 'moverStock') {
        this.moverStockObj.origen = this.$store.state.auth.user.restauranteTpv;
        this.moverStockObj.destino = this.$store.getters['ALMACENES'].filter(r => r.id != this.$store.state.auth.user.restauranteTpv)[0].id
        this.moverStockObj.cantidad = 1;
      } else if (v == 'addStock') {
        this.addStockObj.destino = this.$store.getters['ALMACENES'].filter(r => r.id == this.$store.state.auth.user.restauranteTpv)[0].id
        this.addStockObj.cantidad = 1;
      } else {
        this.moverStockObj = {
          origen: null,
          destino: null,
          cantidad: 0,
          cantidadInicial: 0
        };
        this.addStockObj = {
          destino: null,
          cantidad: 0,
          cantidadAdd: 0,
          cantidadInicial: 0,
        };
      }
    },
    idle(v) {
      if (v) {//pasó el tiempo
        this.editarProductoSistemaEditando = null;
      }
    },
    editarProductoSistemaEditando(v) {
      if (!!v) {

        let obj = useIdle(20 * 1000)
        // const { inc, count } = useCounter()
        this.lastActive = obj.lastActive
        this.idle = obj.idle;
      }
    },
    pasoPidiendoOpciones(v) {
      this.paginarOpcionesCalcular();
    },
    editarProductoObj(v) {
      if (v) {
      }
    },
    menuDialog(v) {
      if (!v) {
        this.$store.commit("productos/setProductoPidiendoMenu", null);
      }
    },
    pistolaTextoDefinitivo(v) {
      if (v.length > 0) {
        this.buscarPorCodigo(v)
          .then(() => {
            // this.$store.commit("setPistolaTextoDefinitivo", "");
          })
          .finally(() => {
            this.$store.commit("setPistolaTextoDefinitivo", "");
          });
      } else {
      }
      // this.$store.commit("setPistolaTextoDefinitivo", "");
    },
    "nuevoProducto.porPeso"(v) {
      if (v) {
        this.$store.dispatch("configuracion/conectarBalanza");
      } else {
        this.$store.dispatch("configuracion/cerrarBalanza");
      }
    },
    productoPesoDialogo(v) {
      if (!v) {
        this.$store.dispatch("configuracion/cerrarBalanza");
      }
    },
    nuevoProductoDialog(v) {
      if (!v) {
        this.nuevoProducto = {
          nombre: "",
          precio: 0,
          impuesto: "",
          cantidad: 1,
          porPeso: false,
          guardar: this.configuracionTpv.productosGestiona,
          precioBase: 0,
          peso: 0,
        };
        this.$store.dispatch("teclado/esconderTeclado");
      } else {
        this.nuevoProducto.guardar = this.configuracionTpv.productosGestiona;
        this.$store.commit("teclado/setLanzarNumerico");
        this.nuevoProducto.impuesto =
          this.$store.state.restauranteObj &&
            this.$store.state.restauranteObj.impuesto
            ? this.$store.state.restauranteObj.impuesto
            : "";
        setTimeout(() => {
          this.$refs.nuevoProductoNombre.focus();
        }, 100);
        // this.$refs.nuevoProductoNombre.$el.input.focus();
        if (this.$store.state.necesarioTeclado && !this.nuevoProductoTeclado) {
          setTimeout(() => {
            this.nuevoProductoTeclado = new Keyboard("tecladoNuevoProducto", {
              onChange: this.onChangeTecladoNuevoProducto,
              onKeyPress: this.onKeyPressTecladoNuevoProducto,
              /**
               * layout, display, theme ... aquí *
               **/
            });
          }, 100);
        }
      }
    },
    addProducto(v) {
      if (!v) {
        setTimeout(() => {
          this.pasoPidiendo = 0;
          this.$store.commit("productos/setCategoriaPidiendo", null);
          this.$store.commit("productos/setProductoPidiendo", null);
        }, 500);
      }
    },
    pasoPidiendo(v) {
      if (v === 0) {
        // this.$store.commit(
        //   "productos/SET_EDITAR_PRODUCTO_SISTEMA_EDITANDO",
        //   false
        // );
        this.$store.commit("setPaginacionManejando", "categorias");
        this.$store.commit("setPaginaPaginacionCategorias", 0);
        this.$store.commit("productos/reiniciarProductosPidiendo");
      } else if (v === 1) {
        this.$store.commit("setPaginaPaginacionProductos", 0);
        this.$store.commit("setPaginacionManejando", "productos");
      } else if (v === 2) {
        this.pasoPidiendoMenu = 1;

        //this.pasoPidiendoOpciones = 1;
        //this.opcionesProductoDialogo = true;
      }
    },

    buscar(v) {
      if (v.length > 2) {
        var f = new Promise((resolve) => {
          let res = this.$store.getters["productos/PRODUCTOS_CATALOGO"]
            .filter((p) =>
              p.nombreMostrar.toUpperCase().includes(this.buscar.toUpperCase())
            )
            .map((p) => {
              return {
                ...p,
                nombre: mostrarIdioma(p),
                nombreTpv: p.nombreTpv ? p.nombreTpv : mostrarIdioma(p),
                precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
              };
            });
          resolve(res);
        });
        this.loadingBuscar = true;
        f.then((r) => {
          this.resultadosBusqueda = r;
        }).finally(() => (this.loadingBuscar = false));
      } else {
        this.resultadosBusqueda = [];
      }
    },
    opcionesProductoDialogo(v) {
      if (!v) {
        this.$store.commit("productos/reiniciarProductosPidiendo");
        this.editarOpcionSistemaPanel = null;
        //this.editarProductoObj = null;
      } else {
        this.paginarOpcionesCalcular();
        if (this.panelActual && this.panelActual.opciones)
          this.calcularColumnas(this.panelActual.opciones);
      }
    },
  },
  computed: {
    muchasOpciones() {
      return false; // ver que se hace aquí
    },
    paginarOpcionesOpcionesPorPagina() {
      return 19; //ver si viene de admin
    },
    mostrarTodasOpciones: {
      get() {
        return this.$store.state.pedidos.mostrarTodasOpciones;
      },
      set(v) {
        this.$store.commit("pedidos/SET_MOSTRAR_TODAS_OPCIONES", v);
      },
    },
    editarProductoSistemaEditando: {
      get() {
        return this.$store.state.productos.editarProductoSistemaEditando;
      },
      set(v) {
        this.$store.commit("productos/SET_EDITAR_PRODUCTO_SISTEMA_EDITANDO", v);
      },
    },
    nuevoProductoDisabled() {
      return (
        (this.nuevoProducto.guardar && !this.nuevoProducto.categoria) ||
        this.nuevoProducto.nombre.length < 2
      );
    },
    nuevoProductoSistemaDisabled() {
      return this.accionStock !== null; //this.nuevoProducto.guardar && !this.nuevoProducto.categoria || this.nuevoProducto.nombre.length < 2;
    },

    menuDialog: {
      get() {
        return this.$store.state.productos.productoPidiendoMenuDialog;
      },
      set(v) {
        this.$store.commit("productos/setProductoPidiendoMenuDialog", v);
      },
    },
    ...mapState({
      productoPidiendoMenu: (state) => state.productos.productoPidiendoMenu,
    }),
    editarProductoMenuObj: {
      get() {
        return this.$store.state.pedidos.editarProductoMenuObj;
      },
      set(v) {
        this.$store.commit("pedidos/setEditarProductoMenuObj", v);
      },
    },
    menuPidiendo() {
      return !!this.productoPidiendoMenu
        ? {
          ...this.productoPidiendoMenu,
          grupos: this.productoPidiendoMenu
            ? this.productoPidiendoMenu.grupos.map((g) => {
              return {
                ...g,
                nombreMostrar: mostrarIdioma(g),
                descripcionMostrar: mostrarIdioma(g),
                productosGrupo: g.productosGrupo
                  .map((p) => {
                    let foundP = this.$store.getters[
                      "productos/PRODUCTOS_CATALOGO"
                    ].find((pG) => pG.id == p);

                    if (!!foundP) {
                      return {
                        ...foundP,
                        nombre: mostrarIdioma(foundP),
                        nombreTpv: foundP.nombreTpv
                          ? foundP.nombreTpv
                          : mostrarIdioma(foundP),
                        precioMostrar:
                          foundP.precio && foundP.precio > 0
                            ? mostrarMoneda(foundP.precio)
                            : "0.00",
                      };
                    }
                  })
                  .filter((p) => !!p),
              };
            })
            : [],
        }
        : null;
    },
    panelActual() {
      return this.opciones[this.pasoPidiendoOpciones - 1];
    },
    resultadosBusqueda() {
      return this.$store.state.productos.resultadosBusqueda;
    },
    codigoLector: {
      get() {
        return this.$store.state.configuracion.codigoLector;
      },
      set(v) {
        this.$store.commit("configuracion/setCodigoLector", v);
      },
    },
    crearNuevoProductoTipoTeclado() {
      if (
        this.nuevoProductoCampoCambiando == "precio" ||
        this.nuevoProductoCampoCambiando == "cantidad" ||
        this.nuevoProductoCampoCambiando == "precioBase"
      ) {
        return "numerico";
      } else {
        return "completo";
      }
    },
    // resultadosBusqueda() {
    //   if (this.buscar.trim().length > 0) {
    //     return this.$store.getters["productos/PRODUCTOS_CATALOGO"]
    //       .filter((p) =>
    //         p.nombreMostrar.toUpperCase().includes(this.buscar.toUpperCase())
    //       )
    //       .map((p) => {
    //         return {
    //           ...p,
    //           nombre: mostrarIdioma(p.nombre),
    //           precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
    //         };
    //       });
    //   } else {
    //     return [];
    //   }
    // },

    opcionesProductoDialogo: {
      get() {
        return this.$store.state.productos.opcionesProductoDialogo;
      },
      set(v) {
        this.$store.commit("productos/setOpcionesProductoDialogo", v);
      },
    },
    pasoPidiendo: {
      //0 categorías, 1 productos, 2 menú
      get() {
        return this.$store.state.productos.pasoPidiendoProducto;
      },
      set(v) {
        this.$store.commit("productos/setPasoPidiendoProducto", v);
      },
    },
    pasoPidiendoOpciones: {
      get() {
        return this.$store.state.productos.pasoPidiendoProductoOpciones;
      },
      set(v) {
        this.$store.commit("productos/setPasoPidiendoProductoOpciones", v);
      },
    },
    addOpcionValido() {
      return (
        (this.addOpcionDisponible ||
          this.pasoPidiendoOpciones == this.opciones.length) &&
        this.opciones
          .map(
            (op, i) =>
              i + 1 != this.pasoPidiendoOpciones ||
              this.pasoPidiendoOpcionesAnterior.includes(i + 1) ||
              op.validacion.ok
          )
          .every((op) => op) //comprobamos que las vvalidacionese son en eel panel acutal o alguno qu ha recorrido
      );
    },
    disabledCard() {
      return !(!!this.categorias && this.categorias.length > 0);
    },
    categorias() {
      return this.$store.state.productos.categorias
        .map((c) => {
          return {
            ...c,
            nombre: mostrarIdioma(c),
            cantidad: this.$store.getters[
              "productos/PRODUCTOS_CATALOGO"
            ].filter((p) =>
              !!p.categoriaTpv ? p.categoriaTpv == c.id : p.categoria == c.id
            ).length,
          };
        })
        .sort((a, b) => {
          if (this.ordenCategoriaProductos !== null) {
            //por categoría no ordena por precio. O admin o nombre. Nombre es estrictamente null, todo lo que sea distinto es orden admin
            return a.orden > b.orden ? 1 : b.orden > a.orden ? -1 : 0;
          } else {
            return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
          }
        })
        .filter((c) => c.cantidad > 0);
    },
    categoriasPaginados() {
      let categorias = chunkArray(
        this.categorias,
        this.categoriasPaginacionCategorias
      );
      let res = categorias[this.paginaPaginacionCategorias];
      this.$store.commit("setMaximoPaginacionCategorias", categorias.length);
      this.$store.commit(
        "setMostrandoPaginacionCategorias",
        res ? res.length : 0
      );
      return res;
    },
    categoriasDefinitivas() {
      return this.modoPaginacion ? this.categoriasPaginados : this.categorias;
    },

    productosAntes() {
      return this.productosTodos.filter((p) => p.prioridadTpv == "antes");
    },
    productosDespues() {
      return this.productosTodos.filter((p) => p.prioridadTpv == "despues");
    },
    productosTodos() {

      return (
        this.$store.getters["productos/PRODUCTOS_CATALOGO"]
          .filter(p => !p.prodLibre || !this.$store.getters['auth/isComandero'])
          // .filter((p) =>
          //   !!p.categoriaTpv
          //     ? p.categoriaTpv == this.categoriaPidiendo.id
          //     : p.categoria == this.categoriaPidiendo.id
          // )
          .map((p) => {
            let corregido = parsePrecios(p);
            return {
              ...p,
              nombre: mostrarIdioma(p),
              nombreTpv: p.nombreTpv ? p.nombreTpv : mostrarIdioma(p),
              precioMostrar: !!corregido.precioCorregido
                ? "Desde " + mostrarMoneda(corregido.precioCorregido)
                : p.precio
                  ? mostrarMoneda(p.precio)
                  : "",
            };
          })
          .sort((a, b) => {
            if (this.ordenCategoriaProductos == "orden") {
              return a.orden > b.orden ? 1 : b.orden > a.orden ? -1 : 0;
            } else if (this.ordenCategoriaProductos == "precioAsc") {
              return a.precio > b.precio ? 1 : b.precio > a.precio ? -1 : 0;
            } else if (this.ordenCategoriaProductos == "precioDesc") {
              return a.precio > b.precio ? -1 : b.precio > a.precio ? 1 : 0;
            } else {
              return a.nombre > b.nombre ? 1 : b.nombre > a.nombre ? -1 : 0;
            }
          })
      );
    },
    productos() {
      return this.productosTodos.filter((p) =>
        !!p.categoriaTpv
          ? p.categoriaTpv == this.categoriaPidiendo.id
          : p.categoria == this.categoriaPidiendo.id
      );
    },
    productosPaginados() {
      let productos = chunkArray(
        this.productos,
        this.productosPaginacionProductos
      );
      let res =
        this.productos.length === 0
          ? []
          : productos[this.paginaPaginacionProductos];
      this.$store.commit("setMaximoPaginacionProductos", productos.length);
      this.$store.commit(
        "setMostrandoPaginacionProductos",
        res ? res.length : 0
      );
      return res;
    },
    productosDefinitivos() {
      return (
        this.modoPaginacion ? this.productosPaginados : this.productos
      ).map((p) => {
        // let c = this.pedidoEditandoObj.productos.filter(
        //   (pC) => pC.id == p.id
        // ).length;
        return {
          ...p,

          // cantidad: c > 0 ? "x " + c.toString() : false,
        };
      });
    },
    pedidoEditandoObj() {
      return this.$store.state.pedidos.pedidoEditandoObj;
    },
    nombreCategoria() {
      return this.categoriaPidiendo ? this.categoriaPidiendo.nombre : "";
    },
    nombreProducto() {
      return this.productoPidiendo ? this.productoPidiendo.nombre : "";
    },
    ...mapGetters(["paginaActualPaginacion", "cuenta", "botonGrande"]),

    ...mapState([
      "pistolaTextoDefinitivo",
      "modoPaginacion",
      "categoriasPaginacionCategorias",
      "paginaPaginacionCategorias",
      "productosPaginacionProductos",
      "paginaPaginacionProductos",
      "configuracionTpv",
      "sizeBoton",
    ]),
    ordenCategoriaProductos() {
      return !!this.configuracionTpv && !!this.configuracionTpv.ordenProductos
        ? this.configuracionTpv.ordenProductos
        : null; //por defecto es por nombre
    },
    ...mapState({
      productoPidiendo: (state) => state.productos.productoPidiendo,
      productoPidiendoOpciones: (state) =>
        state.productos.productoPidiendoOpciones,
      categoriaPidiendo: (state) => state.productos.categoriaPidiendo,
      pesoBalanza: (state) => state.configuracion.pesoBalanza,
    }),
    addProducto: {
      get() {
        return this.$store.state.productos.pidiendoDialog;
      },
      set(v) {
        this.$store.commit("productos/setPidiendoDialog", v);
      },
    },
    cabeceraStepperOpciones() {
      return this.opciones.filter(
        (value, index, self) =>
          index ===
          self.findIndex((t) => t.nombreMostrar === value.nombreMostrar)
      );
    },
    opciones() {
      const panelesObligatorios = this.productoPidiendo.opciones.filter(panel => {
        return panel.tipo == 'simple' || parseInt(panel.min) > 0 || this.mostrarTodasOpciones
      })
      if (
        panelesObligatorios &&
        Array.isArray(panelesObligatorios)
      ) {
        const opcionesSeleccionadas = this.productoPidiendoOpciones.ids;
        let devolver = panelesObligatorios
          .map((panel) => {
            let msj = [];
            const opcionesDelPanel = this.opcionesCorregidas(
              panel.opciones
            ).map((op) => op.id);

            const opcionesSeleccionadasDelPanel = opcionesSeleccionadas.filter(
              (op) => opcionesDelPanel.includes(op)
            );
            //.filter((op) => opcionesSeleccionadas.includes(op));
            let okSimple = panel.opciones
              .map((op) => op.id)
              .some((r) => opcionesSeleccionadas.includes(r));
            let okMulti =
              (!panel.max ||
                panel.max >= opcionesSeleccionadasDelPanel.length) &&
              (!panel.min || panel.min <= opcionesSeleccionadasDelPanel.length);
            let okUltra =
              (!panel.max ||
                panel.max >= opcionesSeleccionadasDelPanel.length) &&
              (!panel.min || panel.min <= opcionesSeleccionadasDelPanel.length);
            let ingredientesString =
              opcionesSeleccionadasDelPanel.length - panel.max == 1 ||
                panel.min - opcionesSeleccionadasDelPanel.length == 1
                ? " ingrediente"
                : " ingredientes";
            if (panel.tipo === "simple" && !okSimple)
              msj.push("Seleccione una opción");
            if (
              panel.tipo != "simple" &&
              panel.max &&
              panel.max < opcionesSeleccionadasDelPanel.length
            )
              msj.push(
                "-" + (opcionesSeleccionadasDelPanel.length - panel.max) /* +
                    ingredientesString */
              );

            if (
              panel.tipo != "simple" &&
              panel.min &&
              panel.min > opcionesSeleccionadasDelPanel.length
            )
              msj.push(
                "+" + (panel.min - opcionesSeleccionadasDelPanel.length) /* +
                    ingredientesString */
              );

            let ok =
              (panel.tipo === "simple" && okSimple) ||
              (panel.tipo == "multi" && okMulti) ||
              (panel.tipo == "ultramulti" && okUltra);
            return {
              ...panel,
              nombreMostrar: mostrarIdioma(panel),
              validacion: {
                ok: ok,
                msj: !ok ? msj.join(", ") : "Correcto",
              },
              opciones: this.opcionesCorregidas(panel.opciones).map((op) => {
                let f = this.productoPidiendoOpciones.ids.filter(
                  (opPidiendo) => opPidiendo == op.id
                );

                return {
                  ...op,
                  cantidad: f.length,
                  precioCobrarMostrar: op.precioCobrar
                    ? mostrarMoneda(op.precioCobrar)
                    : "0.00",

                  nombreMostrar: mostrarIdioma(op),
                  nombreTpv: op.nombreTpv ? op.nombreTpv : mostrarIdioma(op),
                };
              }),
            };
          })
          .filter((o) => o.opciones.length > 0);

        return devolver;
      } else {
        return [];
      }
    },
    precioProductoSeleccionado() {
      return mostrarMoneda(
        this.productoPidiendo.precio +
        this.productoPidiendoOpciones.precios.reduce(
          (accumulator, currentValue) => accumulator + currentValue,
          0
        )
      );
    },
    precioPesoTotal() {
      return mostrarMoneda(this.productoPidiendo.precio * this.pesoEnVivo);
    },
    precioPesoBase() {
      return mostrarMoneda(this.productoPidiendo.precio);
    },
    pesoEnVivo() {
      return this.pesoBalanza;
    },
    incompatibilidadesActuales() {
      return this.productoPidiendoOpciones.incompatibilidades.flat();
    },

    editarProductoObj: {
      get() {
        return this.$store.state.pedidos.editarProductoObj;
      },
      set(v) {
        this.$store.commit("pedidos/setEditarProductoObj", v);
      },
    },
    editarOpcionSistemaOpcionesMostrar() {
      // let f = this.$store.state.productos.modificadores.find(m => m.id == this.editarOpcionSistemaPanel)
      let fMod = this.$store.getters["productos/MODIFICADORES_MONTADOS"].find(
        (m) => m.id == this.editarOpcionSistemaPanel
      );
      // let fGrupo = this.$store.getters['productos/GRUPOS_MODIFICADORES_MONTADOS'].find(m => m.id == this.productoPidiendo.grupoModificador);
      let fGrupo = this.$store.state.productos.gruposModificadores.find(
        (m) => m.id == this.productoPidiendo.grupoModificador
      );

      if (fMod && fGrupo) {
        let fModEnGrupo = fGrupo.modificadores.find((g) => g.id == fMod.id);

        let opcionesEnGrupo = fModEnGrupo ? fModEnGrupo.opciones : [];

        let dev = fMod.opciones.map((op, i) => {
          let opEnGrupo = opcionesEnGrupo.find((o) => o.id == op.id);
          let opActual = this.panelActual.opciones.find(
            (oActual) => oActual.id == op.id
          );
          return {
            ...(opEnGrupo ? opEnGrupo : {}),
            id: op.id,
            precio:
              opActual && opActual.precioCobrar ? opActual.precioCobrar : 0,
            precioCobrarMostrar:
              opActual && opActual.precioCobrar
                ? mostrarMoneda(opActual.precioCobrar)
                : "0.00",

            nombreMostrar: mostrarIdioma(op),
            nombreTpv: op.nombreTpv ? op.nombreTpv : mostrarIdioma(op),
          };
        });
        return [
          ...dev,
          {
            id: null,
            precio: 0,
            precioCobrarMostrar: 0,
            nombreMostrar: "",
            nombreTpv: "",
          },
        ];
      }
      return [];
    },
  },
  methods: {
    confirmarAddStock() {
      this.loading = true;
      this.$store.dispatch('productos/addCantidadAlmacen', { ...this.addStockObj, ...this.editarProductoSistemaObj.stock, pedido: null, producto: this.editarProductoSistemaObj.id })
        .then(() => {
          this.accionStock = null;
          this.editarProductoSistemaDialog = false;
        })
        .finally(() => this.loading = false)
    },
    confirmarMovimientoStock() {
      this.loading = true;
      this.$store.dispatch('productos/moverCantidadAlmacen', { ...this.moverStockObj, ...this.editarProductoSistemaObj.stock, pedido: null, producto: this.editarProductoSistemaObj.id })
        .then(() => {
          this.accionStock = null;
          this.editarProductoSistemaDialog = false;
        })
        .finally(() => this.loading = false)
    },
    paginarOpcionesCalcular() {
      this.paginarOpcionesOpcionesTotal = this.opcionesCorregidas(
        this.panelActual.opciones
      ).length;
      this.paginarOpcionesNumeroPaginas = parseInt(
        this.paginarOpcionesOpcionesTotal /
        this.paginarOpcionesOpcionesPorPagina
      );
      this.paginarOpciones = this.paginarOpcionesNumeroPaginas > 0;
      this.paginarOpcionesPagina = 0;
    },
    paginarOpcionesPasoMetodo(paso) {
      if (paso == "atras") this.paginarOpcionesPagina--;
      else this.paginarOpcionesPagina++;
    },
    pulsacionLargaOpcion(opcion, panel, orden) {
      this.editarOpcionSistemaDialog = true;
      const obj = {
        ...panel,
        idPanel: panel.id,
        idGrupo: this.productoPidiendo.grupoModificador,
        ...opcion,
        orden: orden + 1,
        idGrupoModificador: this.productoPidiendo.grupoModificador,
      };
      this.editarOpcionSistemaObj = { ...obj };
      this.editarOpcionSistemaObjAntes = { ...obj };
    },
    editarOpcionSistemaGuardar() {
      this.editarSistemaLoading = true;
      this.editarOpcionSistemaObj.cambioOcultar =
        !!this.editarOpcionSistemaObj.ocultar !=
        !!this.editarOpcionSistemaObjAntes.ocultar;
      this.editarOpcionSistemaObj.cambioOrden =
        this.editarOpcionSistemaObj.orden !=
        this.editarOpcionSistemaObjAntes.orden;
      this.editarOpcionSistemaObj.ordenAntes =
        this.editarOpcionSistemaObjAntes.orden;
      this.$store
        .dispatch("productos/editarOpcionSistema", this.editarOpcionSistemaObj)
        .then(() => {
          this.editarOpcionSistemaDialog = false;
          this.$store.commit(
            "productos/setProductoPidiendo",
            this.productosTodos.find((p) => p.id == this.productoPidiendo.id)
          );
          this.editarSistemaLoading = false;
        });
    },
    editarProductoSistemaGuardar() {
      this.$store
        .dispatch(
          "productos/editarProductoSistema",
          this.editarProductoSistemaObj
        )
        .then(() => (this.editarProductoSistemaDialog = false));
    },
    pulsacionLarga(p) {
      console.log(p);
      if (this.configuracionTpv.productosGestiona) {
        this.editarProductoSistemaObj = JSON.parse(JSON.stringify(p));
        this.editarProductoSistemaDialog = true;
      } else {
        alert("Edición de productos desactivada");
      }
    },
    volver() {
      // this.pasoPidiendo--;
      this.pasoPidiendo = 0;
      if (this.editarProductoMenuObj) {
        this.editarProductoMenuObj = null;
        this.$store.commit("productos/setPasoPidiendoProducto", 0);
        this.$store.commit("productos/setProductoPidiendoMenu", null);
      } else {
        if (this.pasoPidiendo < 2) {
          this.$store.commit("productos/setProductoPidiendoMenu", null);
          this.$store.commit("pedidos/setEditarProductoMenuObj", null);
          this.$store.commit("pedidos/setEditarProductoObj", null);
        }
      }

    },
    opcionMultipleUltraCantidad(item) {
      return this.productoPidiendoOpciones && this.productoPidiendoOpciones.ids
        ? this.productoPidiendoOpciones.ids.filter((i) => i == item.id).length
        : 0;
    },
    clickOpcionMultipleUltra(op, accion) {
      this.$store.commit("productos/addRemoveProductoPidiendoOpcionUltra", {
        incompatibilidades: op.incompatibilidades,
        nombreMostrar: op.nombreMostrar,
        nombreTpv: op.nombreTpv,
        id: op.id,
        precioCobrar: op.precioCobrar,
        cantidad: accion == "mas" ? 1 : -1,
      });
    },
    opcionesCorregidas(opciones, calcularPaginar = false) {
      let res = opciones
        .map((op) => {
          return {
            ...op,
            incompatible:
              this.incompatibilidadesActuales.includes(op.id) ||
              op.incompatibilidades.some((i) =>
                this.productoPidiendoOpciones.ids.includes(i)
              ),
          };
        })
        .filter((op) => !op.incompatible);
      if (this.paginarOpciones && calcularPaginar) {
        return chunkArray(res, this.paginarOpcionesOpcionesPorPagina)[
          this.paginarOpcionesPagina
        ];
      } else {
        return res;
      }
    },
    cantidadCarrito(p) {
      if (!Array.isArray(this.pedidoEditandoObj.productos)) return false;
      if (p.menu) {
        let c = this.pedidoEditandoObj.menus.filter((pC) => pC.id == p.id);

        let cantidad = c.reduce((a, b) => a + b.cantidad, 0);
        let res = {
          menu: 0,
          noMenu: c.reduce((a, b) => a + b.cantidad, 0),
        };
        return res.menu > 0 || res.noMenu > 0 ? res : false;
      } else {
        let c = this.pedidoEditandoObj.productos.filter((pC) => pC.id == p.id);

        let cantidad = c.reduce((a, b) => a + b.cantidad, 0);
        let res = {
          menu: c.filter((p) => p.menuId).reduce((a, b) => a + b.cantidad, 0),
          noMenu: c.filter((p) => !p.menuId).reduce((a, b) => a + b.cantidad, 0),
        };
        return res.menu > 0 || res.noMenu > 0 ? res : false;
      }

    },
    cantidadCarritoMenuEligiendo(p) {
      if (!Array.isArray(this.pedidoEditandoObj.productos)) return false;
      let cP = this.pedidoEditandoObj.productos.filter(
        (pMenu) =>
          pMenu.id == p.id && pMenu.menuId == this.productoPidiendoMenu.id
      );

      return cP.reduce((a, b) => a + b.cantidad, 0);
      if (!Array.isArray(this.pedidoEditandoObj.productos)) return false;
      let c = this.pedidoEditandoObj.productos.filter((pC) => pC.id == p.id);
      let cantidad = c.reduce((a, b) => a + b.cantidad, 0);
      let res = {
        menu: c.filter((p) => p.menuId).reduce((a, b) => a + b.cantidad, 0),
        noMenu: c.filter((p) => !p.menuId).reduce((a, b) => a + b.cantidad, 0),
      };
      return res.menu > 0 || res.noMenu > 0 ? res : false;
    },
    buscarPorCodigo(v) {

      if (v.length > 2) {
        var f = new Promise((resolve) => {
          let res = this.$store.getters["productos/PRODUCTOS_CATALOGO"]
            .filter((p) => {
              return (
                !!p.codigoLector &&
                p.codigoLector.toUpperCase().includes(v.toUpperCase())
              );
            })
            .map((p) => {
              return {
                ...p,
                nombre: mostrarIdioma(p),
                precioMostrar: p.precio ? mostrarMoneda(p.precio) : "0.00",
              };
            });
          resolve(res);
        });
        this.loadingBuscar = true;
        return f
          .then((r) => {


            if (r.length > 0)
              if (this.editarProductoSistemaEditando) {
                this.pulsacionLarga(r[0])
              } else {
                return this.$store.dispatch("pedidos/addProducto", {
                  producto: r[0],
                  cantidad: 1,
                });
              }

          })
          .finally(() => (this.loadingBuscar = false));
      } else {
        return Promise.resolve();
        // this.resultadosBusqueda = [];
      }
    },

    reiniciarBusqueda() {
      this.buscar = "";
      // this.$store.commit("teclado/setActualValor", this.buscar);
      this.focusBuscar();
    },
    setNuevoProductoCampoCambiando(campo) {
      this.nuevoProductoCampoCambiando = campo;

      if (this.nuevoProductoTeclado) {
        this.$refs.keyboardRefNumerico.reset();
        if (campo == "nombre") {
          this.nuevoProductoTeclado.setInput(this.nuevoProducto[campo]);
        } else if (campo == "precio" || campo == "precioBase") {
          // this.inputTecladoNumerico = this.nuevoProducto[campo].toString();
          this.inputTecladoNumerico = "";
        } else if (campo == "cantidad") {
          this.inputTecladoNumerico = "";
          // this.nuevoProducto[campo].toString();
        }
      }
    },
    focusBuscar() {
      if (this.$store.state.necesarioTeclado) {
        this.$store.commit("teclado/setMostrarTeclado", true);
        this.$store.commit("teclado/setActualValor", this.buscar);
        this.$store.commit("teclado/setCampoEditarObj", null);

        this.$store.commit("teclado/setCampoEditarObj", this);
        this.$store.commit("teclado/setCampoEditarPropiedad", "buscar");
      }
    },
    onChangeTecladoNuevoProducto(input) {
      // this.nuevoProductoCampoCambiando = input;
      if (
        this.nuevoProductoCampoCambiando == "precio" ||
        this.nuevoProductoCampoCambiando == "precioBase"
      )
        input = parseInt(input) / 100;
      this.nuevoProducto[this.nuevoProductoCampoCambiando] = input;

      if (
        (this.nuevoProductoCampoCambiando == "precio" ||
          this.nuevoProductoCampoCambiando == "precioBase" ||
          this.nuevoProductoCampoCambiando == "cantidad") &&
        input == "C"
      ) {
      }
    },
    onKeyPressTecladoNuevoProducto(button) {
      this.$emit("onKeyPress", button);
      /**
       * If you want to handle the shift and caps lock buttons
       */
      if (button === "{shift}" || button === "{lock}") this.handleShift();
    },
    onChange(input) {
      if (input.charAt(input.length - 1) == "C") {
        this.inputTecladoNumerico = "0";
      } else {
        this.inputTecladoNumerico = input;
      }
      if (isFloat(parseFloat(this.inputTecladoNumerico)))
        this.inputTecladoNumerico = "0";
    },
    onKeyPressTecladoNuevoProductoNumerico(button) {
      if (button == "C") {
        this.inputTecladoNumerico = "0";
      }
    },
    onChangeTecladoNuevoProductoNumerico(input) {
      if (
        this.nuevoProductoCampoCambiando == "precio" ||
        this.nuevoProductoCampoCambiando == "precioBase"
      )
        input = mostrarMoneda(parseInt(input) / 100);
      this.nuevoProducto[this.nuevoProductoCampoCambiando] = input;
      if (isFloat(parseFloat(this.inputTecladoNumerico)))
        this.inputTecladoNumerico = "0";
      // if (input.toString().charAt(input.length - 1) == "C") {
      //   this.inputTecladoNumerico = "0";
      // } else {
      //   this.inputTecladoNumerico = input;
      // }
      // if (isFloat(parseFloat(this.inputKeyboard))) this.inputKeyboard = "0";
    },
    onKeyPress(button) {
      if (button == "C") {
        this.$refs.keyboardRefNumerico.reset();
      }
    },
    cambiarPagina(i) {
      this.$store.dispatch("cambiarPagina", i);
    },
    addNuevoProducto() {
      let impFound = this.$store.state.impuestos.find(
        (i) => i.id == this.nuevoProducto.impuesto
      );
      this.nuevoProducto.peso = this.pesoEnVivo;
      if (impFound) {
        let precio = this.nuevoProducto.porPeso
          ? this.nuevoProducto.precioBase
          : this.nuevoProducto.precio;
        let cantidad = this.nuevoProducto.porPeso
          ? this.nuevoProducto.peso
          : this.nuevoProducto.cantidad;
        let productoInsertar = {
          id: this.nuevoProducto.guardar ? key() : moment().unix(),
          nombre: this.nuevoProducto.nombre,
          categoria: this.nuevoProducto.guardar
            ? this.nuevoProducto.categoria
            : "personalizado",
          precio: parseFloat(
            this.nuevoProducto.precio.toString().replace(",", ".")
          ),
          impuesto: this.nuevoProducto.impuesto,
          impPorcentaje: parseFloat(impFound.impuesto),
          tieneOpciones: false,
          opciones: [],
          productoPersonalizado: true,
        };

        if (this.nuevoProducto.porPeso) {
          productoInsertar.precioPesoTotal = mostrarMoneda(
            this.nuevoProducto.precio * this.nuevoProducto.peso
          );
          productoInsertar.precioPesoBase = this.nuevoProducto.precioBase;
          productoInsertar.porPeso = true;
        }

        this.$store.dispatch("pedidos/addProducto", {
          producto: productoInsertar,
          cantidad: cantidad,
        });

        if (this.nuevoProducto.guardar) {
          this.$store.dispatch("productos/guardarProducto", productoInsertar);
        }
        this.nuevoProductoDialog = false;
      }
    },
    elegirCategoria(c) {
      this.$store.commit("productos/setCategoriaPidiendo", c);
      this.pasoPidiendo = 1;
    },
    pasoAtras() {
      this.pasoPidiendoOpciones = this.pasoPidiendoOpcionesAnterior.pop();
      this.addOpcionDisponible = false;
      //console.log(this.productoPidiendoOpciones);
      //this.productoPidiendoOpciones.pop();
      //hay que deseleccionar la opción del panel
    },
    pasoAdelanteBool() {
      var algunaOpcionEncontrada = false;
      for (
        let index = this.pasoPidiendoOpciones;
        index < this.opciones.length;
        index++
      ) {
        const element = this.opciones[index];
        let opcionesValidas = this.opcionesCorregidas(element.opciones);
        if (opcionesValidas.length > 0) {
          algunaOpcionEncontrada = true;
          break;
        }
      }
      return algunaOpcionEncontrada;
    },
    pasoAdelante() {
      //comprobar qu elos modaalees siquieente tiene opciones
      var algunaOpcionEncontrada = false;

      if (this.pasoPidiendoOpciones == this.opciones.length) {
        //último modal, añadimos
        this.addProductoBtn(false);
        return;
      }
      for (
        let index = this.pasoPidiendoOpciones;
        index < this.opciones.length;
        index++
      ) {
        const element = this.opciones[index];
        let opcionesValidas = this.opcionesCorregidas(element.opciones);
        if (opcionesValidas.length > 0) {
          algunaOpcionEncontrada = true;
          this.pasoPidiendoOpcionesAnterior.push(this.pasoPidiendoOpciones);
          this.pasoPidiendoOpciones = index + 1;
          break;
        } else {
          this.addOpcionDisponible = true;
        }
      }

      /*if (this.pasoPidiendoOpciones < this.opciones.length) {
        this.pasoPidiendoOpciones++;
      }*/
    },
    elegirOpcion(op, panel) {
      if (panel.tipo == "simple") {
        panel.opciones.map((op) => {
          this.$store.commit("productos/removeProductoPidiendoOpcion", op.id);
        });
        this.$store.commit("productos/addRemoveProductoPidiendoOpcion", {
          incompatibilidades: op.incompatibilidades,
          nombreMostrar: op.nombreMostrar,
          nombreTpv: op.nombreTpv,
          id: op.id,
          precioCobrar: op.precioCobrar,
          cantidad: 1,
          panel: panel.id,
        });
        this.pasoAdelante();
      } else if (panel.tipo == "multi") {
        this.$store.commit("productos/addRemoveProductoPidiendoOpcion", {
          incompatibilidades: op.incompatibilidades,
          nombreMostrar: op.nombreMostrar,
          nombreTpv: op.nombreTpv,
          id: op.id,
          precioCobrar: op.precioCobrar,
          cantidad: 1,
        });
      }
    },
    conectarLector() {
      //this.$store.dispatch("configuracion/conectarLectorCodigo");
      // this.$store.dispatch("configuracion/conectarLectorCodigo");
    },
    async elegirProducto(p) {
      if (this.editarProductoSistemaEditando) {
        this.pulsacionLarga(p);
      } else {
        let obligatorioOpciones =
          !!p.mostrarSiempreTpv ||
          (!!p.opciones &&
            p.opciones.length > 0 && //no tiene opciones
            p.opciones.filter((panel) => {
              return (
                (panel.tipo == "simple" &&
                  panel.opciones.filter((op) => op.defecto).length == 0) ||
                parseInt(panel.min) > 0
              );
            }).length > 0); //tiene opciones pero ninguna es obligatoria, se añade del tirón

        var hayQueElegir = obligatorioOpciones;

        //agregamos opciones por defecto, que siempre hay que agregarlas
        if (p.opciones) {
          let agregar = p.opciones
            .map((panel) => panel.opciones)
            .flat()
            .filter((o) => o.defecto);

          agregar.forEach((op) => {
            this.$store.commit("productos/addRemoveProductoPidiendoOpcion", {
              incompatibilidades: op.incompatibilidades,
              nombreMostrar: mostrarIdioma(op),
              nombreTpv: !!op.nombreTpv ? op.nombreTpv : mostrarIdioma(op),
              id: op.id,
              precioCobrar: op.precioCobrar,
              cantidad: 1,
            });
          });
        }

        if (hayQueElegir) {
          this.$store.commit("productos/setProductoPidiendo", p);
          this.pasoPidiendoOpciones = 1;
          this.opcionesProductoDialogo = true;
          //this.pasoPidiendo = 2;
        } else if (!!p.porPeso) {
          this.$store.commit("productos/setProductoPidiendo", p);
          this.$store.dispatch("configuracion/conectarBalanza");
          this.productoPesoDialogo = true;
        } else if (!!p.menu) {
          const idconcretoNuevo = key();
          this.$store.commit("productos/setProductoPidiendoMenu", {
            ...p,
            idconcreto: idconcretoNuevo,
          });
          this.pasoPidiendo = 2;
          //this.$store.commit("productos/setProductoPidiendoMenuDialog", true);
        } else {
          if (this.menuPidiendo) {
            //estamos en menú,
            //buscamos menu con hueco en el grupo
            let menuValido = this.$store.getters[
              "pedidos/MENUS_EN_CARRITO"
            ].find((menu) => {
              return (
                menu.productos.filter(
                  (prod) =>
                    prod.menuIdGrupo ==
                    this.menuPidiendo.grupos[this.pasoPidiendoMenu - 1].id
                ).length == 0
              );
            });
            if (this.editarProductoMenuObj) {
              //buscamos si hay producto de ese grupo
              //hay que eliminar el producto que hay
              //si existe hueco en grupo y plato, 1- se agrega al menú 2- menú nuevo
              let f = this.pedidoEditandoObj.productos.find(
                (pMenu) =>
                  pMenu.cantidad > 0 &&
                  pMenu.menuIdConcreto == this.menuPidiendo.idconcreto &&
                  pMenu.menuIdGrupo ===
                  this.menuPidiendo.grupos[this.pasoPidiendoMenu - 1].id
              );

              if (f) {
                this.$store.dispatch("pedidos/cambiarCantidadCarrito", {
                  producto: f,
                  cantidad: 0,
                });
              }
            } else if (menuValido) {
              this.menuPidiendo.idconcreto = menuValido.menuIdConcreto;
            } else {
              this.menuPidiendo.idconcreto = this.menuPidiendo.idconcreto;
              //agergamos también menú en la propiedad menu de editarProducto
              await this.$store.dispatch("pedidos/addMenu", {
                menu: this.menuPidiendo,
                cantidad: 1,
              });
            }
          }
          var prodLibre = {};
          if (p.prodLibre) {
            var idLibre = p.id + "_" + moment().unix();
            prodLibre.id = idLibre;
            prodLibre.idLibre = p.id;
          }

          this.$store.dispatch("pedidos/addProducto", {
            producto: { ...p, ...prodLibre },
            cantidad: 1,
            opciones: this.productoPidiendoOpciones,
            ...(!!this.menuPidiendo
              ? {
                menuId: this.menuPidiendo.id,
                menuIdConcreto: this.menuPidiendo.idconcreto,
                menuIdGrupo:
                  this.menuPidiendo.grupos[this.pasoPidiendoMenu - 1].id,
                menuIdGrupoOrden: this.pasoPidiendoMenu,
                menuCopy: {
                  nombre: this.menuPidiendo.nombre,
                  descripcion: this.menuPidiendo.descripcion
                    ? this.menuPidiendo.descripcion
                    : "",
                  precio: parseFloat(this.menuPidiendo.precio),
                },
              }
              : {}),
          });

          if (p.prodLibre) {
            //acceso directo a editar product
            let prodEditar = {
              ...p,
              id: idLibre,
              opciones: [],
              cantidad: 1,
              precioProductoTotalUnitario: parseFloat(p.precio),
              precioProductoTotal: parseFloat(p.precio),
              impuesto: p.impPorcentaje,
            };

            this.$store.commit("pedidos/setEditarProductoObj", prodEditar);
            return;
          }

          if (!!this.editarProductoMenuObj) {
            this.editarProductoMenuObj = this.$store.getters[
              "pedidos/MENUS_EN_CARRITO"
            ].find(
              (m) =>
                m.menuIdConcreto == this.editarProductoMenuObj.menuIdConcreto
            );
          }

          if (!!this.editarProductoObj) {
            // si estamos editando el producto restamos uno
            this.$store.dispatch("pedidos/cambiarCantidadCarrito", {
              producto: this.editarProductoObj,
              cantidad: this.editarProductoObj.cantidad - 1,
              precioProductoTotalUnitario: parseFloat(
                this.editarProductoObj.precioProductoTotalUnitario
              ),
            });
            this.editarProductoObj.cantidad--;
            if (this.editarProductoObj.cantidad === 0) {
              this.editarProductoObj = null;
            }
          }

          if (this.menuPidiendo && !this.editarProductoMenuObj) {
            //regeneramos el id de menu concreto una vez creado el producto
            this.menuPidiendo.idconcreto = key();
          }
        }
      }
    },
    addProductoPeso() {
      this.$store.dispatch("pedidos/addProducto", {
        producto: {
          ...this.productoPidiendo,
          precioPesoTotal: this.precioPesoTotal,
          precioPesoBase: this.precioPesoBase,
          porPeso: true,
        },
        cantidad: this.pesoEnVivo,
        // cantidad: 0.23,
      });
      this.productoPesoDialogo = false;
    },
    addProductoBtn(volver = true) {
      if (!!this.editarProductoObj) {
        this.$store.dispatch("pedidos/cambiarCantidadCarrito", {
          producto: this.editarProductoObj,
          cantidad: this.editarProductoObj.cantidad - 1,
          precioProductoTotalUnitario: parseFloat(
            this.editarProductoObj.precioProductoTotalUnitario
          ),
        });
        this.editarProductoObj.cantidad--;
        if (this.editarProductoObj.cantidad === 0) {
          this.editarProductoObj = null;
        }
      }

      if (!!this.menuPidiendo) volver = false;
      if (this.menuPidiendo) {
        //estamos en menú,

        //si existe hueco en grupo y plato, 1- se agrega al menú 2- menú nuevo
        let f = this.pedidoEditandoObj.productos.find(
          (pMenu) =>
            pMenu.cantidad > 0 &&
            pMenu.menuId == this.menuPidiendo.id &&
            pMenu.menuIdGrupo !==
            this.menuPidiendo.grupos[this.pasoPidiendoMenu - 1].id
        );

        //buscamos menu con hueco en el grupo
        let menuValido = this.$store.getters["pedidos/MENUS_EN_CARRITO"].find(
          (menu) => {
            return (
              menu.productos.filter(
                (prod) =>
                  prod.menuIdGrupo ==
                  this.menuPidiendo.grupos[this.pasoPidiendoMenu - 1].id
              ).length == 0
            );
          }
        );
        if (this.editarProductoMenuObj) {
        } else if (menuValido) {
          this.menuPidiendo.idconcreto = menuValido.menuIdConcreto;
        } else {
          this.menuPidiendo.idconcreto = key();
          //agergamos también menú en la propiedad menu de editarProducto
          this.$store.dispatch("pedidos/addMenu", {
            menu: this.menuPidiendo,
            cantidad: 1,
          });
        }
      }
      this.$store
        .dispatch("pedidos/addProducto", {
          producto: this.productoPidiendo,
          opciones: this.productoPidiendoOpciones,
          cantidad: 1,
          ...(!!this.menuPidiendo
            ? {
              menuId: this.menuPidiendo.id,
              menuIdConcreto: this.menuPidiendo.idconcreto,
              menuIdGrupo:
                this.menuPidiendo.grupos[this.pasoPidiendoMenu - 1].id,
              menuIdGrupoOrden: this.pasoPidiendoMenu,
              menuCopy: {
                nombre: this.menuPidiendo.nombre,
                descripcion: this.menuPidiendo.descripcion
                  ? this.menuPidiendo.descripcion
                  : "",
                precio: parseFloat(this.menuPidiendo.precio),
              },
            }
            : {}),
        })
        .then(() => {
          if (volver) this.pasoPidiendo = 0;
          this.opcionesProductoDialogo = false;
        });
    },
    beforeDestroy() {
      this.editarOpcionSistemaPanel = null;
    },
    mounted() {
      if (this.pasoPidiendo == 0) {
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@media (min-width: 768px) {
  .custom4cols {
    flex-basis: 14%;
    width: 25%;
    max-width: 14%;
  }

  .custom5cols {
    flex-basis: 14%;
    width: 20%;
    max-width: 14%;
  }

  .custom6cols {
    flex-basis: 14%;
    width: 16%;
    max-width: 14%;
  }

  .custom7cols {
    flex-basis: 14%;
    width: 14%;
    max-width: 14%;
  }

  .custom8cols {
    flex-basis: 12%;
    width: 12%;
    max-width: 12%;
  }

  .custom9cols {
    flex-basis: 11%;
    width: 11%;
    max-width: 11%;
  }

  .custom10cols {
    flex-basis: 10%;
    width: 10%;
    max-width: 10%;
  }
}

.stepper--sticky-header {
  overflow: visible;

  >.v-stepper__header {
    position: sticky;
    top: -1px;
    z-index: 1;
  }
}
</style>

<style lang="scss">
.tecladoNuevoProducto {
  background: transparent;
  border-radius: 8px;
}

.custom-badge {
  &.producto-badge {
    left: -8px;
    top: -8px;
  }
}

.custom-badge {
  &.producto-menu {
    top: -8px;
    left: 25px;
  }
}

.custom-badge {
  &.producto-price {
    bottom: -8px;
    right: -8px;
  }
}

.custom-badge {
  &.stock-label {
    bottom: 0px;
    left: 0px;
    border-radius: 0 !important;
  }
}
</style>
